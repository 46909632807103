<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <template v-slot:options>
        <FormButtonGroup align="end" spaced>
          <FormButtonMenu
            type="secundario"
            :options="operacoesOptions"
            @click="op => selectOperacao(op)"
            :loading="loadingAcao"
          >
            Operação em massa
          </FormButtonMenu>
        </FormButtonGroup>
      </template>
      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        :filters="filters"
        :autoSearch="false"
        sortBy="nome"
        :sortDesc="false"
        @click="onClick"
        ref="datatable"
        showSelect
        noHideFilters
        v-model="itensSelecionado"
      >
        <template v-slot:idpedido="{ row }">
          <FormButtonMenu
            icon
            :options="tableOptions"
            @click="op => selectOption(row, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
        <template v-slot:datapedido="{ col }">
          {{ col | formatDateTime(getTimeZone) }}
        </template>
        <template v-slot:statuspedido="{ col }">
          {{ statusLabel[col] }}
        </template>
        <template v-slot:total_pago="{ col }"> R$ {{ col }} </template>
        <template v-slot:total_liquido="{ col }"> R$ {{ col }} </template>
        <template v-slot:total_estornado="{ col }"> R$ {{ col }} </template>
      </DataTable>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view"></router-view>
    </transition>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import Panel from '@/components/Panel'
import moment from 'moment'
import { mapGetters } from 'vuex'
import FormButtonGroup from '@/components/form/FormButtonGroup'
import {
  PAGAMENTO_ESTORNO,
  PAGAMENTO_ESTORNO_FEITOS
} from '@/store/actions/pagamento'
import MarketingModules from '@/components/MarketingModules'
import { ModulesEnum } from '@/store/actions/modulesEnum'

export default {
  name: 'PainelPagamentos',
  components: {
    DataTable,
    FormButtonMenu,
    Panel,
    FormButtonGroup,
    MarketingModules
  },
  data: () => ({
    headers: [
      {
        text: 'Data pedido',
        value: 'datapedido'
      },
      {
        text: 'Pedido',
        value: 'numpedido'
      },
      { text: 'Cliente', value: 'nome_cliente' },
      {
        text: 'Valor pago',
        align: 'center',
        value: 'total_pago'
      },
      {
        text: 'Status do pedido',
        align: 'center',
        value: 'statuspedido'
      },
      {
        text: 'Total pedido',
        align: 'center',
        value: 'total_liquido'
      },
      {
        text: 'Valor estornado',
        value: 'total_estornado'
      },
      {
        text: 'Valor a estornar',
        value: 'deveria_estornar',
        editable: true,
        width: '150px',
        mask: 'money',
        clickable: false,
        checkDisabled: true
      },
      {
        text: 'Opções',
        value: 'idpedido',
        align: 'center',
        clickable: false
      }
    ],
    tableOptions: [
      { text: 'Detalhes pedido', value: 'pedido' },
      { text: 'Detalhes cliente', value: 'cliente' },
      { text: 'Detalhes pagamento', value: 'pagamento' }
    ],
    filters: [
      { label: 'Numero do pedido', query: 'numpedido' },
      { label: 'Data do pedido inicio', query: 'pedidoinicio', type: 'date' },
      { label: 'Data do pedido fim', query: 'pedidofim', type: 'date' },
      {
        label: 'Data do pagamento inicio',
        query: 'pagamentoinicio',
        type: 'date'
      },
      { label: 'Data do pagamento fim', query: 'pagamentofim', type: 'date' },
      { label: 'CPF/CNPJ do cliente', query: 'cpf' },
      {
        label: 'Estorno',
        query: 'estorno',
        options: [
          { text: 'Todos', value: '' },
          { text: 'Pendente', value: 'pendente' }
        ]
      },
      {
        label: 'Status pedido',
        query: 'statuspedido',
        default: 'ENTREGUE',
        options: [
          { text: 'Todos', value: '' },
          { text: 'Entregue', value: 'ENTREGUE' },
          { text: 'Em entrega', value: 'EMENTREGA' },
          { text: 'Cancelado', value: 'CANCELADO' },
          { text: 'Pronto para retirada', value: 'PRONTO_RETIRADA' },
          { text: 'Pronto para entrega', value: 'PAGO' },
          { text: 'Cancelado', value: 'CANCELADO' }
        ]
      }
    ],
    operacoesOptions: [
      { text: 'Estorno parcial', value: 'estorno_percial' },
      { text: 'Estorno total', value: 'estorno_total' }
    ],
    loadingAcao: false,
    itensSelecionado: [],
    statusLabel: {
      ORCAMENTO_NOVO: 'Orçamento novo',
      ORCAMENTO_PRECIFICADO: 'Orçamento precificado',
      ENVIADO: 'Novo pedido',
      SEPARANDO: 'Em separação',
      AGUARDANDOPAGAMENTO: 'Aguardando pagamento',
      PAGO: 'Pronto para entrega',
      PRONTO_RETIRADA: 'Pronto para retirada',
      EMENTREGA: 'Em entrega',
      ENTREGUE: 'Entregue',
      CANCELADO: 'Cancelado'
    },
    inputEstorno: {},
    modulosForm: [
      ModulesEnum.PAINEL_CLIENTE,
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ]
  }),
  computed: {
    ...mapGetters(['getFornecedorId', 'getTimeZone', 'moduloFornecedorAtivo']),
    endpoint() {
      return `/api/v4/payment/datatable/${this.getFornecedorId}`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  methods: {
    selectOption(row, opt) {
      switch (opt.value) {
        case 'pedido':
          return this.$router.push({
            path: '/fornecedor/pedidos/pedido',
            query: {
              numped: row.numpedido
            }
          })
        case 'cliente':
          return this.$router.push(
            `/fornecedor/cadastro/cliente/${row.idcliente}`
          )
        case 'pagamento':
          this.$router.push(`pagamentos/${row.numpedido}`)
          return
      }
    },
    selectOperacao(opt) {
      switch (opt.value) {
        case 'estorno_percial':
          return this.onRefund(this.itensSelecionado)
        case 'estorno_total':
          return this.onRefund(this.itensSelecionado, true)
      }
    },
    onClick(row, col) {
      switch (col.value) {
        case 'numpedido':
          return this.$router.push({
            path: '/fornecedor/pedidos/pedido',
            query: {
              numped: row.numpedido
            }
          })
        case 'nome_cliente':
          return this.$router.push(
            `/fornecedor/cadastro/cliente/${row.idcliente}`
          )
        default:
          return this.$router.push(`pagamentos/${row.numpedido}`)
      }
    },
    async onRefund(orders, total = false) {
      if (orders.length <= 0) {
        return this.$vueOnToast.pop(
          'error',
          'Selecione os pagamentos que deseja estornar'
        )
      }
      const payloadOrders = orders.map(o => ({
        numorder: o.numpedido,
        valorEstorno: Number(o.deveria_estornar.replace(',', ''))
      }))

      const refunds = await this.$store
        .dispatch(PAGAMENTO_ESTORNO_FEITOS, {
          orders: payloadOrders
        })
        .then(data => data)
        .catch(() => [])

      const title = refunds.length
        ? `Você já realizou estornos similares. Você confirma o estorno?`
        : `Você confirma o estorno ${total ? 'total' : 'parcial'} de ${
            orders.length
          } pagamentos?`

      const html = refunds.length
        ? `${refunds.reduce((acc, cv) => {
            acc += `Pedido ${cv.numorder} - R$${(cv.ValorEstorno / 100).toFixed(
              2
            )} no dia ${moment(cv.date).format('DD/MM/YYYY')} <br>`
            return acc
          }, '')}`
        : 'Esta ação é irreversível, confira se os valores estão corretos.'

      const options = {
        title,
        html
      }
      this.confirmSwal(options, () => this.doRefund(orders, total))
    },
    doRefund(orders, total = false) {
      const estornos = orders.map(item => ({
        numpedido: item.numpedido,
        valor:
          Number(
            `${item.deveria_estornar}`.replace(',', '.').replace(/\s/g, '')
          ) || 0
      }))
      this.loadingAcao = true
      this.$store
        .dispatch(PAGAMENTO_ESTORNO, {
          estornos,
          total
        })
        .then(() => {
          this.$vueOnToast.pop('success', 'Estorno executado, confira os logs')
          this.$refs.datatable.reload()
          this.loadingAcao = false
        })
        .catch(() => (this.loadingAcao = false))
    }
  }
}
</script>
