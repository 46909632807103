var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showModuloForm === false)?_c('div',[_c('MarketingModules')],1):(_vm.showModuloForm === true)?_c('div',{staticClass:"page"},[_c('Panel',{attrs:{"lineColor":"var(--primary-color)"},scopedSlots:_vm._u([{key:"options",fn:function(){return [_c('FormButtonGroup',{attrs:{"align":"end","spaced":""}},[_c('FormButtonMenu',{attrs:{"type":"secundario","options":_vm.operacoesOptions,"loading":_vm.loadingAcao},on:{"click":function (op) { return _vm.selectOperacao(op); }}},[_vm._v(" Operação em massa ")])],1)]},proxy:true}])},[_c('DataTable',{ref:"datatable",attrs:{"headers":_vm.headers,"endpoint":_vm.endpoint,"filters":_vm.filters,"autoSearch":false,"sortBy":"nome","sortDesc":false,"showSelect":"","noHideFilters":""},on:{"click":_vm.onClick},scopedSlots:_vm._u([{key:"idpedido",fn:function(ref){
var row = ref.row;
return [_c('FormButtonMenu',{attrs:{"icon":"","options":_vm.tableOptions},on:{"click":function (op) { return _vm.selectOption(row, op); }}},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("fas fa-ellipsis-v")])],1)]}},{key:"datapedido",fn:function(ref){
var col = ref.col;
return [_vm._v(" "+_vm._s(_vm._f("formatDateTime")(col,_vm.getTimeZone))+" ")]}},{key:"statuspedido",fn:function(ref){
var col = ref.col;
return [_vm._v(" "+_vm._s(_vm.statusLabel[col])+" ")]}},{key:"total_pago",fn:function(ref){
var col = ref.col;
return [_vm._v(" R$ "+_vm._s(col)+" ")]}},{key:"total_liquido",fn:function(ref){
var col = ref.col;
return [_vm._v(" R$ "+_vm._s(col)+" ")]}},{key:"total_estornado",fn:function(ref){
var col = ref.col;
return [_vm._v(" R$ "+_vm._s(col)+" ")]}}]),model:{value:(_vm.itensSelecionado),callback:function ($$v) {_vm.itensSelecionado=$$v},expression:"itensSelecionado"}})],1),_c('transition',{attrs:{"name":"slide-popup","mode":"out-in"}},[_c('router-view',{staticClass:"view"})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }